.navbar-light .navbar-nav .nav-link {
  position: relative;

  &.active:after, &:hover:after {
    border-bottom: 2px solid $baby-blue;
  }

  &:after {
    position: absolute;
    right: 1rem;
    bottom: calc(-.5rem);
    left: 1rem;
    border-bottom: 2px solid transparent;
    transition: border-color .3s cubic-bezier(.645,.045,.355,1);
    content: "";
  }
}

.navbar-brand {
  font-weight: bold;
}

@include media-breakpoint-up(lg) {
  .container-narrow {
    max-width: 960px;
  }
}
