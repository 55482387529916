// Customisation
@import "variables";
@import "custom-variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// Our custom global styles
@import "dropdown";
@import "custom";

@import "~react-bootstrap-typeahead/css/Typeahead";
@import "~react-bootstrap-typeahead/css/Typeahead.bs5";

