// Colours

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$baby-blue:                 #1890ff;

// Typography
$font-family-sans-serif:      "Inter", "Helvetica Neue", Arial, sans-serif;

$font-size-base:              .875rem; // `14px`

$headings-font-weight:        600;

// Body
$body-bg:                   #f0f2f5;
$body-color:                $gray-700;

//Forms

$input-bg:                              $white;

// Navs

$nav-link-color: rgba($black, .55);

// Navbar

$navbar-nav-link-padding-x:         1rem;
$navbar-light-hover-color:          $baby-blue;
$navbar-light-active-color:          $baby-blue;
$navbar-light-brand-color:          $gray-800;
$navbar-light-brand-hover-color:          $gray-700;
