//
// dropdown.scss
//

.dropdown-toggle:after {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  margin-left: .5em;
}

.dropdown-menu {
    box-shadow: $shadow;
}

// Dropdown Animated (Custom)
.dropdown-menu-animated {
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;

    &.show {
        top: 100%!important;
    }

    i {
        display: inline-block;
    }

    &.dropdown-menu-right {
        right: 0!important;
        left: auto!important;
    }

    &.dropdown-menu[x-placement^=right],
    &.dropdown-menu[x-placement^=top],
    &.dropdown-menu[x-placement^=left]{
        top: auto !important;
        animation: none !important;
    }
}

@keyframes DropDownSlide {
    100% {
        transform: translateY(0)
    }
    0% {
        transform: translateY(20px)
    }
}


// Dropdown Large (Custom)
@media (min-width: 600px) {
    .dropdown-menu-lg {
        width: $dropdown-lg-width;
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $dropdown-link-color;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
    }
}

.dropdown-btn {
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $input-border-width solid $input-border-color;
  width: 40px;

  &:hover {
    background-color: $white;
  }
}

.dropdown-btn-clear {
  background-color: $form-select-bg;
  border-left: 0;
  border-right: $input-border-width solid $input-border-color;
  border-top: $input-border-width solid $input-border-color;
  border-bottom: $input-border-width solid $input-border-color;
  color: $gray-500;

  &:hover, &:focus {
    background-color: $white;
    border-color: $input-border-color;
    color: $gray-500;
  }
}

// Dropdown arrow hide
.arrow-none {
    &:after {
        display: none;
    }
}
